import React from "react";
import Img from "gatsby-image";

import PropTypes from "prop-types";

const propTypes = {
	useGatsbyImg: PropTypes.bool,
	fluid: PropTypes.object,
	alt: PropTypes.string,
	src: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

const defaultProps = {
	useGatsbyImg: true,
	fluid: {},
	alt: "",
	src: "",
	className: "",
};

function ImgEl({ useGatsbyImg, className, alt, fluid, src }) {
	return useGatsbyImg ? (
		<Img fluid={fluid} className={className} alt={alt} />
	) : (
		<img className={className} src={src} alt={alt} />
	);
}

ImgEl.propTypes = propTypes;
ImgEl.defaultProps = defaultProps;

export default ImgEl;
