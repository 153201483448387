import React, { useContext } from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import sanitize from "sanitize-html";
import { useInView } from "react-intersection-observer";

import { formatLineBreaks } from "../../../utils";
import { PreloaderContext } from "../../Preloader/Preloader";

import styles from "./Masthead.module.scss";

const propTypes = {
	CMSView: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	role: PropTypes.string.isRequired,
	technology: PropTypes.string.isRequired,
	awards: PropTypes.string.isRequired,
};

const animation = (animate, delay = 0) => {
	return {
		initial: {
			opacity: 0,
			y: 25,
		},
		animate: {
			opacity: animate ? 1 : 0,
			y: animate ? 0 : 25,
		},
		transition: {
			delay,
			ease: [0.33, 1, 0.68, 1],
			duration: 1.2,
		},
	};
};

function Masthead({ CMSView, title, description, role, technology, awards }) {
	const { isPreloaderComplete = false } = useContext(PreloaderContext) || {};
	const [sectionRef, inView] = useInView({
		threshold: 0,
		triggerOnce: true,
	});

	return (
		<section ref={sectionRef} className={styles.wrapper__main}>
			<section className={styles.masthead__wrapperMain}>
				<div className={`${styles.wrapper__label} ${styles.masthead__wrapperLabel}`}>
					Description
				</div>
				<div className={styles.wrapper__content}>
					{CMSView ? (
						<>
							<h1 className={styles.masthead__title}>{title}</h1>
							<h2
								className={styles.masthead__description}
								dangerouslySetInnerHTML={{ __html: sanitize(description) }}
							/>
						</>
					) : (
						<>
							<motion.h1
								className={styles.masthead__title}
								{...animation(isPreloaderComplete && inView)}
							>
								{title}
							</motion.h1>
							<motion.h2
								className={styles.masthead__description}
								dangerouslySetInnerHTML={{ __html: sanitize(description) }}
								{...animation(isPreloaderComplete && inView, 0.3)}
							/>
						</>
					)}
				</div>
			</section>

			<section className={styles.responsibilities__wrapperMain}>
				<div className={styles.wrapper__label}>Responsibilities</div>
				<div className={styles.wrapper__content}>
					{CMSView ? (
						<div className={styles.responsibilities__wrapperContent}>
							<div className={styles.responsibilities__wrapperCol}>
								<div className={styles.responsibilities__label}>Role</div>
								{role &&
									formatLineBreaks(role).map((item) => (
										<h3 key={item} className={styles.responsibilities__item}>
											{item}
										</h3>
									))}
							</div>
							<div className={styles.responsibilities__wrapperCol}>
								<div className={styles.responsibilities__label}>Technology</div>
								{technology &&
									formatLineBreaks(technology).map((item) => (
										<h3 key={item} className={styles.responsibilities__item}>
											{item}
										</h3>
									))}
							</div>
							<div className={styles.responsibilities__wrapperCol}>
								<div className={styles.responsibilities__label}>Awards</div>
								{awards &&
									formatLineBreaks(awards).map((item) => (
										<h3 key={item} className={styles.responsibilities__item}>
											{item}
										</h3>
									))}
							</div>
						</div>
					) : (
						<motion.div
							className={styles.responsibilities__wrapperContent}
							{...animation(isPreloaderComplete && inView, 0.6)}
						>
							<div className={styles.responsibilities__wrapperCol}>
								<div className={styles.responsibilities__label}>Role</div>
								{role &&
									formatLineBreaks(role).map((item) => (
										<h3 key={item} className={styles.responsibilities__item}>
											{item}
										</h3>
									))}
							</div>
							<div className={styles.responsibilities__wrapperCol}>
								<div className={styles.responsibilities__label}>Technology</div>
								{technology &&
									formatLineBreaks(technology).map((item) => (
										<h3 key={item} className={styles.responsibilities__item}>
											{item}
										</h3>
									))}
							</div>
							<div className={styles.responsibilities__wrapperCol}>
								<div className={styles.responsibilities__label}>Awards</div>
								{awards &&
									formatLineBreaks(awards).map((item) => (
										<h3 key={item} className={styles.responsibilities__item}>
											{item}
										</h3>
									))}
							</div>
						</motion.div>
					)}
				</div>
			</section>
		</section>
	);
}

Masthead.propTypes = propTypes;

export default Masthead;
