import React, { useContext } from "react";
import PropTypes from "prop-types";
import uniqid from "uniqid";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { isArray, isEmpty } from "lodash";

import ImgEl from "./ImgEl/ImgEl";
import { PreloaderContext } from "../../Preloader/Preloader";

import styles from "./Snapshots.module.scss";

const propTypes = {
	CMSView: PropTypes.bool,
	title: PropTypes.string.isRequired,
	heroVisual: PropTypes.object.isRequired,
	snapshots: PropTypes.array.isRequired,
};

const defaultProps = {
	CMSView: false,
};

const animation = (animate, delay = 0) => {
	return {
		initial: {
			opacity: 0,
			y: 25,
		},
		animate: {
			opacity: animate ? 1 : 0,
			y: animate ? 0 : 25,
		},
		transition: {
			delay,
			ease: [0.33, 1, 0.68, 1],
			duration: 1.2,
		},
	};
};

function Snapshots({ CMSView, title, heroVisual, snapshots }) {
	const { isPreloaderComplete = false } = useContext(PreloaderContext) || {};
	const [sectionRef, inView] = useInView({
		threshold: 0,
		triggerOnce: true,
	});

	return (
		<motion.section
			ref={sectionRef}
			className={styles.snapshots__wrapperMain}
			{...animation(isPreloaderComplete && inView, 0.8)}
		>
			<ImgEl
				useGatsbyImg={!CMSView}
				src={heroVisual}
				fluid={heroVisual?.childImageSharp?.fluid}
				className={styles.snapshots__asset}
				alt={`Featured visual of ${title}`}
			/>

			{isArray(snapshots) && !isEmpty(snapshots)
				? snapshots.map((item) => {
						const fluid = item?.childImageSharp?.fluid;
						const strItemExt = typeof item === "string" ? item.split(".").pop() : null;

						// image
						if (
							(item.extension && item.extension !== "mp4") ||
							(typeof item === "string" && strItemExt !== "mp4")
						) {
							return (
								<ImgEl
									key={uniqid()}
									useGatsbyImg={!CMSView}
									fluid={fluid}
									src={item}
									className={styles.snapshots__asset}
									alt={`Featured visual of ${title}`}
								/>
							);
						}

						// video
						if (
							(item.extension && item.extension === "mp4") ||
							(typeof item === "string" && strItemExt === "mp4")
						) {
							return (
								<video
									key={uniqid()}
									preload="true"
									autoPlay
									muted
									loop
									playsInline
									className={`${styles.snapshots__asset} ${styles[`is--video`]}`}
								>
									<source
										src={item?.publicURL || item}
										type={`video/${item?.extension || strItemExt}`}
									/>
								</video>
							);
						}

						return null;
				  })
				: null}
		</motion.section>
	);
}

Snapshots.propTypes = propTypes;
Snapshots.defaultProps = defaultProps;

export default Snapshots;
