import React from "react";
import { Link } from "gatsby";

import styles from "./Footer.module.scss";

function Footer() {
	return (
		<section className={styles.footer__wrapper}>
			<Link className={styles.footer__button} to="/">
				Back To home
			</Link>
		</section>
	);
}

export default Footer;
