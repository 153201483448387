import React from "react";
import { Link } from "gatsby";
import { PropTypes } from "prop-types";

import Masthead from "../Masthead/Masthead";
import Snapshots from "../Snapshots/Snapshots";
import Footer from "../Footer/Footer";

import styles from "./WorkView.module.scss";

const propTypes = {
	/** Flag to determine if Main component has to render canvas element
	 *  – Parsed down from wrapper components (WorkEntry & WorkPreview)
	 */
	CMSView: PropTypes.bool,
	/** Data returned from parent component's graphql query */
	title: PropTypes.string.isRequired,
	/** Data returned from parent component's graphql query */
	projectDescription: PropTypes.string.isRequired,
	/** Data returned from parent component's graphql query */
	heroVisual: PropTypes.object.isRequired,
	/** Data returned from parent component's graphql query */
	role: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
	/** Data returned from parent component's graphql query */
	technology: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
	/** Data returned from parent component's graphql query */
	awards: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
	/** Data returned from parent component's graphql query */
	snapshots: PropTypes.array.isRequired,
};

const defaultProps = {
	CMSView: false,
	role: [],
	technology: [],
	awards: [],
};

export default function WorkView({
	CMSView,
	title,
	projectDescription,
	heroVisual,
	role,
	technology,
	awards,
	snapshots,
}) {
	const LinkEl = {
		type: CMSView ? "a" : Link,
		toProp: CMSView ? null : "/",
		hrefProp: CMSView ? "/" : null,
		altProp: CMSView ? "button" : null,
	};

	return (
		<section className={styles.wrapper__mainSection}>
			<LinkEl.type
				to={LinkEl.toProp}
				href={LinkEl.hrefProp}
				alt={LinkEl.altProp}
				className={styles.closeButton}
			/>

			<Masthead
				CMSView={CMSView}
				title={title}
				description={projectDescription}
				role={role}
				technology={technology}
				awards={awards}
			/>

			<Snapshots CMSView={CMSView} title={title} heroVisual={heroVisual} snapshots={snapshots} />

			<Footer />
		</section>
	);
}

WorkView.propTypes = propTypes;
WorkView.defaultProps = defaultProps;
