import React from "react";
import { graphql, navigate } from "gatsby";
import { get } from "lodash";
import PropTypes from "prop-types";

import WorkView from "../components/Works/WorkView/WorkView";

import SEO from "../components/SEO/SEO";

const propTypes = {
	/** built-in data  */
	data: PropTypes.object.isRequired,
	/** built-in data  */
	location: PropTypes.object.isRequired,
};

export default function WorkEntry({ data, location }) {
	const { pathname } = location;
	const dataObj = get(data, "markdownRemark.frontmatter");

	// return back to index page if no slug is present
	if (typeof window !== "undefined" && dataObj.title === "") {
		navigate("/");
		return null;
	}

	return (
		<>
			<SEO
				title={dataObj.title}
				image={dataObj.heroVisual && dataObj.heroVisual.publicURL}
				pathname={pathname}
				description={dataObj.projectDescription}
				article
			/>

			<WorkView
				CMSView={false}
				title={dataObj.title}
				projectDescription={dataObj.projectDescription}
				heroVisual={dataObj.heroVisual}
				role={dataObj.role}
				technology={dataObj.technology}
				awards={dataObj.awards}
				snapshots={dataObj.snapshots}
			/>
		</>
	);
}

WorkEntry.propTypes = propTypes;

export const query = graphql`
	query($slug: String!) {
		markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			frontmatter {
				title
				heroVisual {
					childImageSharp {
						fluid(maxWidth: 2000, quality: 100) {
							...GatsbyImageSharpFluid_withWebp_noBase64
						}
					}
					publicURL
				}
				projectDescription
				role
				technology
				awards
				snapshots {
					childImageSharp {
						fluid(maxWidth: 2000, quality: 100) {
							...GatsbyImageSharpFluid_withWebp_noBase64
						}
					}
					extension
					publicURL
				}
			}
		}
	}
`;
